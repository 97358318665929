import { Image } from "antd";
import { Fome } from "assets/images/images";
import Context from "context/context";
import { useContext } from "react";

const Contact: React.FC = () => {
  const hash = useContext(Context);
  return (
    <div
      className={`${
        hash?.hash === "kontakt" ? "pt-32" : "pt-20"
      } bg-darkergreen py-20 myContainer grid md:grid-cols-2 gap-7`}
      id="kontakt"
    >
      <div>
        <p className="santorini text-6xl mt-5">Kontakt</p>
        <p className="text-white text-lg mt-12">
          Unsere Treuzeugin Amelie und unseren Trauzeugen Mario erreicht Ihr
          unter folgenden
        </p>
        <div className="grid grid-cols-2 gap-5 mt-12">
          <div>
            <p className="text-fonts text-4xl santorini">Amelie</p>
            <p className="text-white font-light text-lg mt-5">
              0176 70 36 44 22
            </p>
          </div>
          <div>
            <p className="text-fonts text-4xl santorini">Mario</p>
            <p className="text-white font-light text-lg mt-5">
              0176 60 98 72 39
            </p>
          </div>
        </div>
        <p className="text-lg mt-12 text-white">
          Wie Ihr uns erreicht, wisst Ihr ja :)
        </p>
      </div>
      <div className="flex md:justify-end">
        <div className="w-[300px] md:w-[350px]">
          <Image src={Fome} className="rounded-md" width={"100%"} />
        </div>
      </div>
    </div>
  );
};

export default Contact;
