import { NavBar } from "components/components";
import "./App.css";
import { ConfigProvider } from "antd";
import JosefinSans from "assets/fonts/JosefinSans.ttf";
import {
  Contact,
  HeroSection,
  Hotel,
  Location,
  Misc,
  ReturnMessage,
  Schedule,
} from "sections/sections";
import State from "context/state";

function App() {
  return (
    <State>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: JosefinSans,
          },
        }}
      >
        <HeroSection />
        <NavBar />
        <Location />
        <ReturnMessage />
        <Schedule />
        <Hotel />
        <Misc />
        <Contact />
      </ConfigProvider>
    </State>
  );
}

export default App;
