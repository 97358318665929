import Context from "context/context";
import { useContext } from "react";

const Misc: React.FC = () => {
  const hash = useContext(Context);
  return (
    <div
      className={`${
        hash?.hash === "misc" ? "pt-40" : "pt-24"
      } bg-lightgreen pb-24 myContainer grid md:grid-cols-2 gap-7`}
      id="misc"
    >
      <div className="md:border-r-[3px] border-b-[3px] md:border-b-0 pb-7 md:pb-0 border-white">
        <p className="santorini text-4xl md:text-6xl">Dresscode</p>
        <p className="text-lg font-light mt-10">
          Kommt gerne sommerlich-schick.
        </p>
        <p className="text-lg mt-5 font-light">
          Wichtig ist uns, dass Ihr Euch wohl fühlt und in Eurem Outfit gut das
          Tanzbein schwingen könnt.
        </p>
      </div>
      <div className="pt-4 md:pt-0">
        <p className="santorini text-4xl md:text-6xl">Wünsche</p>
        <p className="text-lg font-light mt-10">
          Unser größter Wunsch ist, an diesem besonderen Tag gemeinsam mit Euch zu feiern!
        </p>
        <p className="text-lg mt-5 font-light">
          Dass Ihr dabei seid, ist das schönste Geschenk. Wenn Ihr uns darüber
          hinaus noch etwas schenken möchtet, freuen wir uns über einen Beitrag
          zu unserer Flitterkasse.
        </p>
      </div>
    </div>
  );
};

export default Misc;
