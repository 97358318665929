import { hotel_cover } from "assets/images/images";
import React from "react";
import { Timeline } from "antd";

const Schedule: React.FC = () => {
  const backgroundImage: React.CSSProperties = {
    backgroundImage: `url(${hotel_cover})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <div className="bg-lightgreen pt-24 myContainer" style={backgroundImage}>
      <p className="text-center santorini text-4xl md:text-6xl">
        Ablauf 29.Juni 2024
      </p>
      <Timeline
        className="mt-24"
        mode={"right"}
        items={[
          {
            color: "#9DA598",
            label: <p className="text-lg md:text-3xl">18:00Uhr</p>,
            children: (
              <p className="text-lg md:text-3xl">Apéro & Hochzeitsrede</p>
            ),
          },
          {
            color: "#9DA598",
            label: <p className="text-lg md:text-3xl">19:30Uhr</p>,
            children: <p className="text-lg md:text-3xl">Dinnerbuffet</p>,
          },
          {
            color: "#9DA598",
            label: (
              <p className="text-lg md:text-3xl">(mit Mitternachtssnack)</p>
            ),
            children: <p className="text-lg md:text-3xl">Party!</p>,
          },
          {
            color: "#9DA598",
            label: <p className="text-lg md:text-3xl">03:45Uhr</p>,
            children: <p className="text-lg md:text-3xl">Last Dance</p>,
          },
        ]}
      />
    </div>
  );
};

export default Schedule;
