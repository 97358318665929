import { Image } from "antd";
import { Dance } from "assets/images/images";

const HeroSection: React.FC = () => {
  return (
    <div className="md:h-screen py-10 md:py-0 bg-lightgreen myContainer grid md:grid-cols-2 grid-cols-1 gap-5 items-center">
      <div className="absolute top-10 left-0 hidden md:block">
        <div className="flex">
          <div className="border-t-[3px] mt-[10px] w-24 border-darkergreen"></div>
          <p className="text-darkergreen text-xl ml-4">WEBSEITE</p>
        </div>
      </div>
      <div className="text-fonts">
        <p className="santorini lg:text-7xl md:text-5xl text-4xl text-center md:text-start">
          Verena{" "}
          <span className="text-darkergreen md:text-9xl text-7xl">+</span>{" "}
          Alexander
        </p>
        <p className="lg:text-xl mt-14">
          Am 29. Juni 2024 feiern wir unsere Hochzeit und laden Euch herzlich
          dazu ein.
        </p>
        <p className="font-light lg:text-lg mt-7">
          Wir sind überglücklich, wenn wir Euch an diesem Tag an unserer Seite
          haben und gemeinsam einen wunderbaren Abend verbringen.
        </p>
        <p className="font-light lg:text-lg py-2">
          Auf dieser Website findet Ihr alle Infos zur Feier und drumherum.
        </p>
        <p className="font-light text-lg">Wir freuen uns auf Euch!</p>
      </div>
      <Image src={Dance} className="rounded-md" />
    </div>
  );
};

export default HeroSection;
