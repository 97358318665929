import { useContext, useState } from "react";
import { Affix } from "antd";
import Context from "context/context";
import { cross_icon, menu_icon } from "assets/icons/icons";
import "./navbar.css";

interface MobHeaderProps {
  handleLinks: (e: any) => void;
}

const MobNavBar: React.FC<MobHeaderProps> = (props) => {
  const [showOptions, setShowOptions] = useState("headerClose");
  const handleOptions = () => {
    if (showOptions === "headerClose") {
      setShowOptions("headerOpen");
    } else {
      setShowOptions("headerClose");
    }
  };
  const handleMobLink = (e: any) => {
    props.handleLinks(e);
    setShowOptions("headerClose");
  };
  return (
    <>
      <div className="lg:hidden shadow-md bg-white h-24 myContainer flex items-center justify-between">
        <a
          onClick={handleMobLink}
          href="#"
          className="text-lightgreen santorini text-3xl text-center md:text-start"
        >
          Verena <span className="text-darkergreen text-4xl">+</span> Alexander
        </a>
        <img
          onClick={handleOptions}
          className="cursor-pointer"
          src={showOptions === "headerClose" ? menu_icon : cross_icon}
          alt=""
        />
      </div>
      <div
        className={`${showOptions} bg-white shadow-md myContainer absolute w-full`}
      >
        {showOptions === "headerOpen" && (
          <div className="text-fonts text-lg flex flex-col items-center leading-10">
            <a href="#location" onClick={handleMobLink}>
              Location
            </a>
            <a href="#rueckmeldung" onClick={handleMobLink}>
              Rückmeldung
            </a>
            <a href="#hotel" onClick={handleMobLink}>
              Hotel
            </a>
            <a href="#misc" onClick={handleMobLink}>
              Sonstiges
            </a>
            <a href="#kontakt" onClick={handleMobLink}>
              Kontakt
            </a>
          </div>
        )}
      </div>
    </>
  );
};

const NavBar: React.FC = () => {
  const hash = useContext(Context);
  const handleLinks = (e: any) => {
    // setTimeout(() => {
    // }, 200);
    hash?.setHash(e.target.href.split("#")[1]);
  };
  return (
    <Affix offsetTop={0}>
      <MobNavBar handleLinks={handleLinks} />
      <div className="myContainer lg:flex items-center justify-between h-24 shadow-md bg-white navbar hidden">
        <a
          onClick={handleLinks}
          href="#"
          className="text-lightgreen santorini lg:text-3xl md:text-5xl text-4xl text-center md:text-start"
        >
          Verena{" "}
          <span className="text-darkergreen md:text-4xl text-7xl">+</span>{" "}
          Alexander
        </a>
        <div className="text-fonts text-lg">
          <a href="#location" onClick={handleLinks}>
            Location
          </a>
          <a href="#rueckmeldung" className="ml-8" onClick={handleLinks}>
            Rückmeldung
          </a>
          <a href="#hotel" className="ml-8" onClick={handleLinks}>
            Hotel
          </a>
          <a href="#misc" className="ml-8" onClick={handleLinks}>
            Sonstiges
          </a>
          <a href="#kontakt" className="ml-8" onClick={handleLinks}>
            Kontakt
          </a>
        </div>
      </div>
    </Affix>
  );
};

export default NavBar;
