import { useState } from "react";
import Context from "./context";

interface AuthStateProps {
  children: React.ReactNode;
}

const State: React.FC<AuthStateProps> = (props) => {
  const { children } = props;
  const [hash, setHash] = useState("");
  return (
    <Context.Provider value={{ hash, setHash }}>{children}</Context.Provider>
  );
};

export default State;
