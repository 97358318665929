import type { CollapseProps } from "antd";
import { Button, Collapse, Image } from "antd";
import { Waterfront1, Waterfront2, Waterfront3 } from "assets/images/images";
import Context from "context/context";
import { useContext } from "react";

const items: CollapseProps["items"] = [
  {
    key: "1",
    label: (
      <p className="text-lg text-white font-semibold">
        Zimmer: Urban Premium Studio
      </p>
    ),
    children: (
      <p className="text-lg font-light">
        Kosten pro Nacht: 125 € (Frühstück dazu buchbar)<br/>
        Weitere Infos zum Zimmer und Hotel findet ihr auf der Webseite (Kein Buchungslink):{" "}
        <a
          href="https://thewaterfront.habyt.com/"
          target="_blank"
          className="underline" rel="noreferrer"
        >
          https://thewaterfront.habyt.com/
        </a>
      </p>
    ),
  },
  {
    key: "2",
    label: (
      <p className="text-lg text-white font-semibold">
        Adresse und Anfahrt
      </p>
    ),
    children: (
      <ul className="text-lg font-light list-disc pl-5">
        <p>Das HABYT liegt ganz in der Nähe der Location:</p>
        <p>Hauptstraße 2-3, 10317 Berlin</p>
        <li>5 Minuten mit dem Taxi / Uber</li>
        <li>10 Minuten mit Bus oder Tram</li>
        <li>15 Minuten zu Fuß</li>
      </ul>
    ),
  },
];

const Hotel: React.FC = () => {
  const hash = useContext(Context);
  return (
    <div
      className={`${
        hash?.hash === "hotel" ? "pt-40" : "pt-24"
      } myContainer bg-darkergreen pb-24 grid md:grid-cols-5 gap-10 items-center`}
      id="hotel"
    >
      <div className="md:col-span-3">
        <p className="santorini text-4xl md:text-6xl">
          Unterkünfte
        </p>
        <p className="font-light mt-7 text-xl"><b>(wichtig: bitte frühzeitig buchen!)</b></p>
        <div className="mt-12">
          <div className="text-white">
            <p className="font-light">
              Berlin bietet eine Vielzahl an verschiedensten
              Übernachtungsmöglichkeiten. Allerdings steigt am 29. Juni neben
              unserer noch eine zweite große Party in der Stadt, und zwar eines
              der Achtelfinal-Spiele der Fußball-EM 2024. Deshalb empfehlen wir
              Euch, frühzeitig nach einer Unterkunft zu schauen.
            </p>
            <p className="font-light mt-7">
              <b>Bis Ende Februar 2024</b> können alle, die möchten, über unseren Buchungslink ein Zimmer für zwei Nächte (28.06.-30.06.) 
              oder auch für eine Nacht (29.06.-30.06.) aus unserem Kontingent reservieren:
            </p>
            <p className="font-light mt-7 text-xl"><b>Hotel HABYT The Waterfront</b></p>
            <Button
              target="_blank"
              href="https://app.mews.com/distributor/04239814-af25-4cab-ab28-b02e00b57f42?mewsAvailabilityBlockId=3260bbd4-06a7-4a5b-9364-b0c700a3e88c"
              className="bg-white h-14 font-semibold flex items-center lg:w-[35%] justify-center text-lg mt-7"
            >
              Zimmer reservieren
            </Button>
            <p className="font-light mt-7">
              Bitte beachtet, dass die Zimmerreservierungen verbindlich und nicht
              stornierbar sind.
            </p>
            <div className="mt-7 dark">
              <Collapse items={items} className="overflow-hidden" />
            </div>
          </div>
        </div>
      </div>
      <div className="md:col-span-2">
        <div className="grid grid-cols-2 gap-3">
          <div className="col-span-2">
            <Image src={Waterfront1} />
          </div>
          <Image src={Waterfront2} />
          <Image src={Waterfront3} />
        </div>
      </div>
      
    </div>
  );
};

export default Hotel;
