import Context from "context/context";
import { useContext, useEffect } from "react";

const ReturnMessage: React.FC = () => {
  const hash = useContext(Context);
  useEffect(() => {
    const loadTallyEmbeds = () => {
      if (typeof (window as any).Tally !== "undefined") {
        (window as any).Tally.loadEmbeds();
      } else {
        const tallyIframes = document.querySelectorAll<HTMLIFrameElement>(
          "iframe[data-tally-src]:not([src])"
        );

        tallyIframes.forEach((iframe) => {
          iframe.src = iframe.dataset.tallySrc || "";
        });
      }
    };

    const scriptSrc = "https://tally.so/widgets/embed.js";

    if (document.querySelector(`script[src="${scriptSrc}"]`) === null) {
      const script = document.createElement("script");
      script.src = scriptSrc;
      script.onload = loadTallyEmbeds;
      script.onerror = loadTallyEmbeds;
      document.body.appendChild(script);
    } else {
      loadTallyEmbeds();
    }
  }, []);
  return (
    <div id="rueckmeldung">
      <div
        className={`${
          hash?.hash === "rueckmeldung" ? "pt-[154px]" : "pt-16"
        } flex flex-col items-center justify-center bg-darkergreen pb-11 myContainer`}
      >
        <p className="text-white text-xl text-center md:text-3xl">
          Bitte gebt uns bis zum 1. Mai 2024 Bescheid, ob Ihr dabei seid
        </p>
        <div className="mt-5 w-full">
          <iframe
            data-tally-src="https://tally.so/embed/mB741e?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
            loading="lazy"
            width="100%"
            height="249"
            title="Tally Embed"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ReturnMessage;
