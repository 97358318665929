import { createContext } from "react";

type ContextType = {
  hash: string;
  setHash: (hash: string) => void;
};

const Context = createContext<ContextType | undefined>(undefined);

export default Context;
