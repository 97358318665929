import { useContext } from "react";
import type { CollapseProps } from "antd";
import { Button, Collapse } from "antd";
import Context from "context/context";

const items: CollapseProps["items"] = [
  {
    key: "1",
    label: (
      <p className="text-lg font-light">
        U-Bahn-Haltestelle{" "}
        <span className="font-semibold">Samariterstraße</span>
      </p>
    ),
    children: (
      <p className="text-lg font-light">
        ca. 10 Min. zu Fuß zur Location (900 m)
      </p>
    ),
  },
  {
    key: "2",
    label: (
      <p className="text-lg font-light">
        Tram-/Bus-Haltestelle{" "}
        <span className="font-semibold">Holteistraße</span>
      </p>
    ),
    children: (
      <p className="text-lg font-light">
        ca. 2 Min. zu Fuß zur Location (200 m)
      </p>
    ),
  },
];

const Location: React.FC = () => {
  const hash = useContext(Context);
  // alert(hash?.hash);
  return (
    <div
      className={`${
        hash?.hash === "location" && "pt-20 bg-lightgreen"
      } grid lg:grid-cols-5 grid-cols-1`}
      id="location"
    >
      <div className="lg:col-span-3 col-span-1 bg-lightgreen myContainer py-20 lg:p-20">
        <p className="santorini text-5xl">Alte Turnhalle Berlin</p>
        <p className="text-lg mt-14">Holteistr. 6-9, 10245 Berlin</p>
        <a
          href="https://www.alte-turnhalle-berlin.de/"
          target="_blank"
          className="font-light underline mt-2 text-lg" rel="noreferrer"
        >
          www.alte-turnhalle-berlin.de
        </a>
        <p className="text-lg font-light mt-5">
          Die Location ist optimal mit öffentlichen Verkehrsmitteln, zu Fuß oder
          mit Uber / Taxi zu erreichen:
        </p>
        <Collapse className="mt-2" items={items} />
        <p className="text-lg font-light mt-3">
          Die Alte Turnhalle hat keinen eigenen Parkplatz. Aufgrund der Lage
          mitten in der Stadt ist öffentliches Parken in den umliegenden Straßen
          zwar möglich, aber nicht zu empfehlen. Außerdem hoffen wir, dass
          sowieso niemand vorhat, mit dem eigenen Auto zur Feier zu kommen :)
        </p>
        <Button
          target="_blank"
          href="https://maps.app.goo.gl/epd1fFGyx6V8JwFe6"
          className="bg-white h-14 font-semibold flex items-center lg:w-[40%] justify-center text-lg mt-5"
        >
          Auf Maps öffnen
        </Button>
      </div>
      <div className="lg:col-span-2 col-span-1">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1021.0341423601398!2d13.463934232484988!3d52.50733722265109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84ef65c4ff71b%3A0xff7f46009286e220!2sAlte%20Turnhalle%20Berlin!5e0!3m2!1sde!2sde!4v1703874235913!5m2!1sde!2sde"
          width="100%"
          className="h-[500px] lg:h-full"
          style={{ border: 0 }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Location;
